import { Contact } from "../components/Contact/Contact";
import { ContinueReading } from "../components/ContinueReading/ContinueReading";
import React from "react";
import Seo from "../components/Seo/seo"

function ContactPage() {
  return (
    <>
      <Seo title={"Contact"}/>
      <div className="sections">
        <section>
          <Contact />
        </section>
        <section>
          <ContinueReading nextSection={"About"} link="#who" />
        </section>
      </div>
    </>
  );
}
export default ContactPage;
